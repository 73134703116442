import * as forge from "node-forge";
import { str2arrayBuffer } from './Util';

class WrongCredentialsError extends Error {
    constructor() {
        super("Hmm, that didn't work. Please check your username / password.");
    }
}

class DecryptError extends Error {
    constructor() {
        super("Could not decrypt data");
    }
}

class PrivateKey {
    constructor(privateKey) {
        this.privateKey = privateKey;
    }

    async decrypt(cipherText) {
        return this.privateKey.decrypt(cipherText, 'RSAES-PKCS1-V1_5');
    }
};

async function importPrivateKey(pemKey, password) {
    const privateKey = forge.pki.decryptRsaPrivateKey(pemKey, password);
    if (privateKey === null)
        throw new WrongCredentialsError();
    return new PrivateKey(privateKey);
};

class AESCryptoKey
{
    constructor(key) {
        this.key = key;
        this.webCryptoKey = null;
    }

    async decrypt(cryptoText, iv, tag = '') {
        const decipher = forge.cipher.createDecipher('AES-GCM', this.key);
        decipher.start({iv, tagLength: tag.length * 8, tag: tag});
        let buf = forge.util.createBuffer(cryptoText);
        decipher.update(buf)
        const res = decipher.finish();
        if (!res)
            throw new DecryptError();
        return decipher.output.data;
    }

    async decryptWithWebCrypto(cryptoText, iv, tag) {
        if (!this.webCryptoKey)
            this.webCryptoKey = await crypto.subtle.importKey(
                'raw', str2arrayBuffer(this.key), 'AES-GCM', false, ['encrypt', 'decrypt']);

        const params = {
            name: "AES-GCM",
            iv: iv,
        };

        params.tagLength = 8 * tag.length;
        const cryptoArray = new Uint8Array(cryptoText.byteLength + tag.byteLength);
        cryptoArray.set(new Uint8Array(cryptoText), 0);
        cryptoArray.set(new Uint8Array(tag), cryptoText.byteLength);

        return window.crypto.subtle.decrypt(
            params,
            this.webCryptoKey,
            cryptoArray.buffer
        );
    }
};

export { importPrivateKey, PrivateKey, AESCryptoKey };
