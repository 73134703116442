import FileSaver from 'file-saver';

// a case when we need to silently download a file using Javascript, and prompt to save it afterwards
function promptToSaveBlob({ content, name}) {
    const blob = new Blob(content, { type: 'octet/stream' });
    FileSaver.saveAs(blob, name);
}

export {
  promptToSaveBlob
};
