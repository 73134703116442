import {AESCryptoKey} from '../Crypto';
import FilespaceConfig from '../FilespaceConfig';
import ObjectStore from './ObjectStore';
import S3ObjectStore from './S3ObjectStore';

const IV_SIZE = 12;

export default class ObjectStoreBuilder
{
    constructor (private cryptoKey: AESCryptoKey, private fsConfig: FilespaceConfig) {}

    async build(): Promise<ObjectStore> {
        if (this.fsConfig.objectStoreType !== 's3')
            throw new Error('Only S3-compatible storage is currently supported!');

        console.log('Decrypting S3 keys ...');
        const accessKey = await this.decryptKey(this.fsConfig.accessKey);
        const secretKey =  await this.decryptKey(this.fsConfig.secretKey);
        console.log(`Decrypted S3 keys for provider ${this.fsConfig.provider}`);

        return new S3ObjectStore({
            accessKey: accessKey,
            secretKey: secretKey,
            bucketName: this.fsConfig.bucketName,
            endpoint: this.fsConfig.endpoint,
            locationConstraint: this.fsConfig.locationConstraint,
            protocol: this.fsConfig.protocol,
            region: this.fsConfig.region,
            virtualAddressing: this.fsConfig.virtualAddressing
        });
    }

    async decryptKey(encryptedValue: string): Promise<string> {
        const iv = encryptedValue.substring(0, IV_SIZE);
        const cryptoText = encryptedValue.substring(IV_SIZE);
        return this.cryptoKey.decrypt(cryptoText, iv);
    }
};
