import LucidClientService from '../lucid/Client';
import { FsEntry } from "../lucid/Directory";
import { SET_CLIENT, CHANGE_DIR, SET_CHILDREN } from "./actionTypes";

export function setClient(client: LucidClientService) {
    return {
        type: SET_CLIENT,
        payload: client
    };
}

export function changeDir(dirEntry: FsEntry) {
    return {
        type: CHANGE_DIR,
        payload: dirEntry
    };
}

export function setChildren(children: FsEntry[]) {
    return {
        type: SET_CHILDREN,
        payload: children
    };
}
