// import JSZip from 'jszip';
import LRU from 'lru-cache'

import LucidClientService from '../Client';
import { ROOT_ID, FsEntry, EntryType, Directory, Entry } from '../Directory';
import { AESCryptoKey } from '../Crypto';

const CACHE_SIZE: number = 200;

interface Resource {
    id: string,
    parentId: string,
    name: string,
    createdDate: number,
    cryptoKey: AESCryptoKey | null,
    modifiedDate: number,
    title: string,
    type: string,
    size: number,
    parents: Array<string>,
    capabilities: [],
    downloadUrl: string,
    mimeType: string,
    exportLinks: []
}

let cache: LRU<string, Resource> = new LRU(CACHE_SIZE)

export interface Options {
    client: LucidClientService;
};

async function init(_options : Options) {
    return {
        apiInitialized: true,
        apiSignedIn: true
    };
}

function normalizeResource(entry: FsEntry, parentIds: Array<string>) : Resource {
    return {
        id: entry.id,
        parentId: entry.parentId,
        name: entry.name,
        createdDate: entry.creationTime,
        modifiedDate: entry.updateTime,
        title: entry.name,
        type: entry.type === EntryType.DIR ? 'dir' : 'file',
        size: entry.size,
        cryptoKey: entry.cryptoKey,
        parents: parentIds,
        capabilities: [],
        downloadUrl: '',
        mimeType: '',
        exportLinks: []
    };
}

async function getResourceById(options: any, id: string) : Promise<Resource> {
    if (id === "" || id === ROOT_ID)  // Root
        return {
            id: ROOT_ID,
            parentId: "",
            name: '/',
            createdDate: 0,
            modifiedDate: 0,
            title: '/',
            type: 'dir',
            size: 0,
            cryptoKey: null,
            parents: [],
            capabilities: [],
            downloadUrl: '',
            mimeType: '',
            exportLinks: []
        };

    const cached = cache.get(id);
    if (cached)
        return cached;

    const entry: Entry = await options.client.getEntry(id);
    const entryRes = entry.get();
    const fsEntry = entryRes.entry;
    const parentIds = entryRes.parentIds;

    const result = normalizeResource(fsEntry, parentIds);
    cache.set(id, result);

    return result;
}

async function getParentsForId(options: any, id: string, result: Array<Resource> = []) : Promise<Array<Resource>> {
    if (id === ROOT_ID || id === "")
        return result;

    const resource = await getResourceById(options, id);
    return traverseParent(options, resource.parentId, result);
}

async function traverseParent(options: any, id: string, result: Array<Resource>) : Promise<Array<Resource>> {
    const resource = await getResourceById(options, id);
    
    if (id === ROOT_ID || id === "")
        return [resource].concat(result);

    return await traverseParent(options, resource.parentId, [resource].concat(result));
}

async function getParentIdForResource(options: any, resource: Resource) {
    if (resource.id === ROOT_ID || resource.id === "")
        return "";

    return resource.parentId;
}

type ResourceKeys = keyof Resource;
interface GetChildrenOptions{ id: string, sortBy: ResourceKeys, sortDirection: string };

async function getChildrenForId(options : Options, { id, sortBy = 'title', sortDirection = 'ASC' } : GetChildrenOptions) {
    const dir: Directory = await options.client.getDir(id);
    const dirRes = dir.list();
    const parentIds = [id].concat(dirRes.parentIds);

    const shouldCacheChildren = dirRes.entries.length < CACHE_SIZE / 4;
    const result = []
    for (const dirEntry of dirRes.entries) {
        const resource = normalizeResource(dirEntry, parentIds);

        if (shouldCacheChildren)
            cache.set(resource.id, resource);

        result.push(resource);
    }

    const mul = sortDirection === "ASC" ? 1 : -1;
    const cmpTypes = function(left: Resource, right: Resource) {
        if (left.type === right.type)
            return 0;

        return left.type === "dir" ? -1 : 1;
    };
    const cmpString = function(left: Resource, right: Resource) {
        const ct = cmpTypes(left, right);
        if (ct !== 0)
            return ct;

        const l = left[sortBy] as string;
        const r = right[sortBy] as string;
        return l.localeCompare(r, undefined, { sensitivity: 'accent' }) * mul;
    };
    const cmpNumber = function(left: Resource, right: Resource) {
        const ct = cmpTypes(left, right);
        if (ct !== 0)
            return ct;

        const l = left[sortBy] as number;
        const r = right[sortBy] as number;
        if (l === r)
            return 0;
        return l > r ? mul : -mul;
    };

    if (["name", "title"].indexOf(sortBy) >= 0)
        result.sort(cmpString);
    else if (["createdDate", "modifiedDate", "size"].indexOf(sortBy) >= 0)
        result.sort(cmpNumber);
    else
        throw new Error("Unsupported sorting key: " + sortBy);
    
    return result;
}

async function getCapabilitiesForResource(options_: any, resource: any) {
    return resource.capabilities || [];
}

async function getRootId() {
    return ROOT_ID;
}

async function uploadFileToId(parentId: string, file: any, onProgress: any) {
    throw new Error('uploadFileToId not implemented');
}

async function createFolder(apiOptions: any, parentId: string, folderName: string) {
    throw new Error('createFolder not implemented');
}

async function renameResource(apiOptions: any, id: string, newName: string) {
    throw new Error('renameResource not implemented');
}

function getResourceName(apiOptions: any, resource: any) {
    return resource.title;
}

async function removeResources() {

}

async function signIn() {
}

async function signOut() {
}

function hasSignedIn() {
    return true;
}

export default {
    init,
    hasSignedIn,
    getResourceById,
    getChildrenForId,
    getRootId,
    getParentsForId,
    getParentIdForResource,
    getCapabilitiesForResource,
    getResourceName,
    createFolder,
    uploadFileToId,
    renameResource,
    removeResources,
    signIn,
    signOut
};
