import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Login from './components/Login';
import FileExplorer from './components/FileExplorer';
import VideoPlayer from './components/VideoPlayer';
import PrivateRoute from './components/PrivateRoute';

function App() {
  return (
    <Router>
    <div className='App'>
      <nav className='navbar navbar-expand-lg navbar-light fixed-top'>
        <div className='container'>
          <div className='collapse navbar-collapse' id='navbarTogglerDemo02'>
            <ul className='navbar-nav ml-auto'>
              <li className='nav-item'>
                <Link className='nav-link' to={'/login'}>Login</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

	  <Switch>
		<Route exact path='/' component={Login} />
		<Route path='/login' component={Login} />
        <PrivateRoute path='/files' component={FileExplorer} />
        <PrivateRoute path='/play' component={VideoPlayer} />
	  </Switch>

	</div>
   </Router>
  );
}

export default App;
