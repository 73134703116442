import { LucidWebApiClient } from './webapiclient/WebApiClient';
import { AESCryptoKey } from './Crypto';
import LoginInfo from './LoginInfo';

class LoginService
{
    constructor(private client: LucidWebApiClient,
                private importPrivateKey: any) {}

    async login(filespace: string, user: string, password: string) : Promise<LoginInfo>
    {
        const info = await this.client.getLoginInfo(filespace, user);
        const encryptedPrivateKey = window.atob(info.keys.privateKey);
        info.keys.privateKey = await this.importPrivateKey(encryptedPrivateKey, password);
        info.keys.configKey = new AESCryptoKey(await info.keys.privateKey.decrypt(window.atob(info.keys.configKey)));
        info.config.accessKey = window.atob(info.config.accessKey);
        info.config.secretKey = window.atob(info.config.secretKey);
        return info;
    }
}

export default LoginService;
