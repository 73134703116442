import {Buffer} from 'buffer';
import lz4 from '../deps/lz4/lib/lz4';

import {arr2str} from './Util';
import ObjectStore from './ObjectStore/ObjectStore';
import {LayoutBlock} from './Layout';
import {AESCryptoKey} from './Crypto';

export default class BlockReader
{
    constructor(private wasm: any,
        private layoutBlock: LayoutBlock,
        private objectStore: ObjectStore,
        private cryptoKey: AESCryptoKey) { }

    async read(): Promise<Uint8Array> {
        const obj = await this.objectStore.getObject(this.layoutBlock.object, this.layoutBlock.dataOffset, this.layoutBlock.dataLen);
        const objBody = new Int8Array(obj.Body);
        const encryptedBlock = new this.wasm.Block(this.layoutBlock.id, objBody);
        const auxData = encryptedBlock.GetAuxData();
        const tag = auxData.GetTag();
        const iv = auxData.GetIv();
        const rawSize = auxData.GetRawSize();
        const compressionScheme = auxData.GetCompressionScheme();

        try {
            const decryptedBlock = await this.cryptoKey.decryptWithWebCrypto(encryptedBlock.Data(), iv, tag);

            if (compressionScheme.length === 0 || +rawSize === 0)
                return new Uint8Array(decryptedBlock);  // block wasn't compressed

            const compressedBuffer = Buffer.from(decryptedBlock);
            const decompressedBuffer = Buffer.alloc(+rawSize)

            const res = lz4.decodeBlock(compressedBuffer, decompressedBuffer, undefined, undefined);
            if (res < 0)
                throw new Error(`Failed to decompress block, error at offset ${-res}: ${decryptedBlock}`);
            return new Uint8Array(decompressedBuffer);
        } finally {
            auxData.delete();
            encryptedBlock.delete();
        }
    }
};
