import React from 'react';
import LoginService from '../lucid/Login';
import { LucidWebApiClient } from '../lucid/webapiclient/WebApiClient';
import { importPrivateKey } from '../lucid/Crypto';

interface WithDefaultProps {
    loginService: any;
};

function WithDefaultContext<P extends object>(WrappedComponent : React.ComponentType<P>) {
    return class Wrapper extends React.Component<P & WithDefaultProps> {
        render() {
            const client = new LucidWebApiClient();
            const loginService = new LoginService(client, importPrivateKey);
            return (
                <WrappedComponent
                    {...this.props}
                    loginService={loginService}
                    apiClient={client}
                />
            );
        }
    };
}

export default WithDefaultContext;
