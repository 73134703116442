import {LayoutResponse} from './Layout';
import {AESCryptoKey} from './Crypto';
import ObjectStore from './ObjectStore/ObjectStore';
import BlockReader from './BlockReader';

const MAX_PREFETCH_BLOCKS = 10;

export class BlockStream {
    private currBlockId: number;
    private nextPrefetchBlock: number;
    private reading: boolean;
    private activePrefetches: any;

    constructor(private wasm: any,
        private objectStore: ObjectStore,
        private layout: LayoutResponse,
        private cryptoKey: AESCryptoKey)
    {
        this.currBlockId = 0;
        this.nextPrefetchBlock = 1;
        this.reading = false;
        this.activePrefetches = {};
    }

    isValid(): boolean
    {
        console.debug(`currBlock: ${this.currBlockId}, blocks len: ${this.layout.blocks.length}`);
        return this.currBlockId < this.layout.blocks.length;
    }

    async readNext(): Promise<Uint8Array> {
        let res : Uint8Array = new Uint8Array();
        if (this.currBlockId >= this.layout.blocks.length)
            return res;

        if (this.reading)
            throw new Error(`Read already in progress!`);

        this.reading = true;
        this.issuePrefetchIos();

        try {
            const prefetchIo = this.activePrefetches[this.currBlockId];
            if (prefetchIo) {
                console.debug(`Prefetch hit for block ${this.currBlockId}`);
                res = await prefetchIo;
                this.discardPrefetchIo(this.currBlockId);
            } else {
                console.debug(`Prefetch miss for block ${this.currBlockId}`);
                res = await this.readBlock(this.currBlockId);
            }
            this.issuePrefetchIos();

            this.currBlockId = this.currBlockId + 1;
        }
        catch (e) {
            console.error(`Error reading block: ${e}`);
            this.currBlockId = this.layout.blocks.length;
            throw e;
        }
        finally {
            this.reading = false;
        }
        return res;
    }

    readBlock(blockId: number): Promise<Uint8Array> {
        const blockReader = new BlockReader(this.wasm, this.layout.blocks[blockId], this.objectStore, this.cryptoKey);
        return blockReader.read();
    }

    issuePrefetchIos() {
        while (this.canPrefetch()) {
            this.issuePrefetchIo(this.nextPrefetchBlock++);
        }
    }

    canPrefetch(): boolean {
        return (Object.keys(this.activePrefetches).length < MAX_PREFETCH_BLOCKS
            && this.nextPrefetchBlock < this.getBlockCount());
    }

    issuePrefetchIo(blockId: number) {
        console.debug(`Prefetching block ${blockId}`);
        this.activePrefetches[blockId] = this.readBlock(blockId);
    }

    discardPrefetchIo(blockId: number) {
        console.debug(`Discarding prefetched block ${blockId}`);
        delete this.activePrefetches[blockId];
    }

    getProgress() {
        return this.currBlockId * 100.0 / this.layout.blocks.length;
    }

    getBlockCount() {
        return this.layout.blocks.length;
    }
}
