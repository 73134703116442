export function arr2str(buf: Int8Array): string {
    const arr = new Uint8Array(buf);
    return byteArr2Str(arr);
}

export function str2arrayBuffer(str: string) {
    var buf = new ArrayBuffer(str.length);
    var bufView = new Uint8Array(buf);
    for (let i = 0; i < str.length; ++i) {
        const code = str.charCodeAt(i);
        if (code > 256) {
            throw new Error(`Tried converting non-byte string to Uint8Array: ${str}`)
        }
        bufView[i] = code;
    }
    return buf;
}


export function byteArr2Str(arr: Uint8Array) {
    let res = '';
    for (let i = 0; i < arr.length; ++i)
    {
        res += String.fromCharCode(arr[i]);
    }
    return res;
}

export function byteStr2Array(str: string): Uint8Array {
    const res = new Uint8Array(str.length);
    for (let i = 0; i < str.length; ++i) {
        const code = str.charCodeAt(i);
        if (code > 256) {
            throw new Error(`Tried converting non-byte string to Uint8Array: ${str}`)
        }
        res[i] = code;
    }
    return res;
}
