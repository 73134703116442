import LucidClientService from '../lucid/Client';
import { connect } from "react-redux";

import React, { Component } from "react";
import { Redirect, Route, RouteProps } from 'react-router-dom'

interface Props extends RouteProps {
    client: LucidClientService
    component: any;
};

interface State { };

class PrivateRoute extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        const { component: Component, client, ...rest } = this.props;

        return (
            <Route
            {...rest}
            render={props =>
                client ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{ pathname: '/login', state: { redirectedFrom: props.location } }} />
                )
            }
            />
        )
    }
};

function mapStateToProps(state: any) {
    return { client: state.clientService };
}

export default connect(mapStateToProps)(PrivateRoute);
