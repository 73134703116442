import { combineReducers } from "redux";
import { SET_CLIENT, CHANGE_DIR, SET_CHILDREN } from "./actionTypes";
import LucidClientService from '../lucid/Client';
import { FsEntry } from "../lucid/Directory";

interface NavigatorContext {
    currentDir: FsEntry | null,
    children: FsEntry[]
};

const defaultNavigatorContext: NavigatorContext = {
    currentDir: null,
    children: []
}

function clientService(state: LucidClientService | null = null, action: any): LucidClientService | null {
    switch (action.type) {
        case SET_CLIENT: {
            return action.payload;
        }
        default:
            return state;
    }
};

function navigatorContext(state: NavigatorContext = defaultNavigatorContext, action: any): NavigatorContext {
    switch (action.type) {
        case CHANGE_DIR: {
            // Execute change directory
            return { ...state, currentDir: action.payload };
        }
        case SET_CHILDREN: {
            // Execute change directory
            return { ...state, children: action.payload };
        }
        default:
            return state;
    }
};

export default combineReducers({ clientService, navigatorContext });
