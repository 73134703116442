const translations = {
  en: {
    uploading: 'Uploading',
    uploadingItem: 'Uploading 1 item',
    uploadingItems: 'Uploading {quantity} items',
    upload: 'Upload',
    remove: 'Remove',
    downloadingName: 'Downloading {name}...',
    downloadingItem: 'Downloading 1 item',
    downloadingItems: 'Downloading {quantity} items',
    download: 'Download',
    rename: 'Rename',
    creating: 'Creating',
    creatingName: 'Creating {name}...',
    create: 'Create',
    createFolder: 'Create folder',
    items: 'items',
    item: 'item',
    error: 'error',
    folderName: 'Folder name',
    fileExist: 'File or folder with name {name} already exists',
    newName: 'New name',
    emptyName: 'Name can\'t be empty',
    tooLongName: 'Name can\'t contain more than 255 characters',
    notAllowedCharacters: 'Name contains not allowed characters',
    title: 'Title',
    fileSize: 'File size',
    lastModified: 'Last modified',
    signInSuccess: 'Logged in successfully',
    signInFail: 'Login failed',
    notInitAPIClient: 'Could not initialize Lucid client',
    successInit: 'Lucid client successfully initialized',
  }
};

export default function getMessage(locale, key, params) {
  const translationExists = (translations[locale] && translations[locale][key]);
  const translation = translationExists ? translations[locale][key] : translations['en'][key];
  if (!params) {
    return translation;
  }

  const re = /{\w+}/g;
  function replace(match) {
    const replacement = match.slice(1, -1);
    return params[replacement] ? params[replacement] : '';
  }

  return translation.replace(re, replace);
}
