import {LucidWebApiClient} from './webapiclient/WebApiClient';
import {Directory, Entry, EncryptedDirInfo, EncryptedEntryInfo} from './Directory';
import LoginInfo from './LoginInfo';
import ObjectStore from './ObjectStore/ObjectStore';
import {FileStore} from './FileStore';
import {AESCryptoKey} from './Crypto';
import getWasmModule from './Wasm';

class LucidClientService
{
    private fileStore?: FileStore;
    private wasm: any;

    constructor(public filespace: string,
        private apiClient: LucidWebApiClient,
        private objectStore: ObjectStore,
        private loginInfo: LoginInfo) {
    }

    async init() {
        if (this.fileStore)
            throw Error("Already initialized!");

        this.wasm = await getWasmModule();
        this.fileStore = new FileStore(
            this.filespace, this.loginInfo.keys.token, this.loginInfo.config, this.apiClient, this.objectStore, this.wasm);
    }

    async getDir(dirId: string): Promise<Directory> {
        let encryptedDirInfo = await this.fetchDir(dirId);
        console.log(encryptedDirInfo);

        for (let i = 0; i < encryptedDirInfo.rootShares.length; ++i) {
            encryptedDirInfo.rootShares[i].encryptedInfo = atob(encryptedDirInfo.rootShares[i].encryptedInfo);
        }

        for (let i = 0; i < encryptedDirInfo.entries.length; ++i) {
            encryptedDirInfo.entries[i].nameIv = atob(encryptedDirInfo.entries[i].nameIv);
            encryptedDirInfo.entries[i].encryptedName = atob(encryptedDirInfo.entries[i].encryptedName);
            encryptedDirInfo.entries[i].encryptedCryptoKey = atob(encryptedDirInfo.entries[i].encryptedCryptoKey);
            encryptedDirInfo.entries[i].keyIv = atob(encryptedDirInfo.entries[i].keyIv);
        }

        for (let i = 0; i < encryptedDirInfo.cryptoChain.length; ++i) {
            encryptedDirInfo.cryptoChain[i].keyIv = atob(encryptedDirInfo.cryptoChain[i].keyIv);
            encryptedDirInfo.cryptoChain[i].encryptedCryptoKey = atob(encryptedDirInfo.cryptoChain[i].encryptedCryptoKey);
        }
    
        let dir = new Directory(dirId, this.loginInfo.keys.privateKey, encryptedDirInfo, this.wasm);
        await dir.init();
        
        return dir;
    }

    async getEntry(entryId: string): Promise<Entry> {
        let encryptedEntryInfo = await this.fetchEntry(entryId);
        console.log(encryptedEntryInfo);

        for (let i = 0; i < encryptedEntryInfo.rootShares.length; ++i) {
            encryptedEntryInfo.rootShares[i].encryptedInfo = atob(encryptedEntryInfo.rootShares[i].encryptedInfo);
        }

        for (let i = 0; i < encryptedEntryInfo.entries.length; ++i) {
            encryptedEntryInfo.entries[i].nameIv = atob(encryptedEntryInfo.entries[i].nameIv);
            encryptedEntryInfo.entries[i].encryptedName = atob(encryptedEntryInfo.entries[i].encryptedName);
            encryptedEntryInfo.entries[i].encryptedCryptoKey = atob(encryptedEntryInfo.entries[i].encryptedCryptoKey);
            encryptedEntryInfo.entries[i].keyIv = atob(encryptedEntryInfo.entries[i].keyIv);
        }

        for (let i = 0; i < encryptedEntryInfo.cryptoChain.length; ++i) {
            encryptedEntryInfo.cryptoChain[i].keyIv = atob(encryptedEntryInfo.cryptoChain[i].keyIv);
            encryptedEntryInfo.cryptoChain[i].encryptedCryptoKey = atob(encryptedEntryInfo.cryptoChain[i].encryptedCryptoKey);
        }
    
        let entry = new Entry(entryId, this.loginInfo.keys.privateKey, encryptedEntryInfo, this.wasm);
        await entry.init();

        return entry;
    }

    readFile(cryptoKey: AESCryptoKey, fileId: string) {
        return this.fileStore!.read(cryptoKey, fileId);
    }

    private async fetchDir(dirId: string): Promise<EncryptedDirInfo> {
        return this.apiClient.listDir(this.filespace, this.loginInfo.keys.token, dirId);
    }

    private async fetchEntry(entryId: string): Promise<EncryptedEntryInfo> {
        return this.apiClient.getEntry(this.filespace, this.loginInfo.keys.token, entryId);
    }
}

export default LucidClientService;
