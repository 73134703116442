import ObjectStore from './ObjectStore';
import AWS from 'aws-sdk';

interface S3Params {
    accessKey: string;
    secretKey: string;
    bucketName: string;
    endpoint: string;
    locationConstraint: boolean;
    protocol: string;
    region: string;
    virtualAddressing: boolean;
};

export default class S3ObjectStore implements ObjectStore {
    private s3: AWS.S3;

    constructor(private params: S3Params) {
        AWS.config.region = params.region;
        AWS.config.credentials = new AWS.Credentials({
            accessKeyId: params.accessKey,
            secretAccessKey: params.secretKey
        });
        AWS.config.s3ForcePathStyle = true;
        let s3Config : AWS.S3.ClientConfiguration = {};
        if (this.params.endpoint)
            s3Config.endpoint = this.params.endpoint;
        s3Config.s3DisableBodySigning = true;
        s3Config.s3BucketEndpoint = false;
        this.s3 = new AWS.S3(s3Config);
        this.headBucket();
    }

    headBucket() {
        return this.s3.headBucket({Bucket: this.params.bucketName}).promise();
    }

    async getObject(objectId: string, offset: number, len: number): Promise<AWS.S3.Types.GetObjectOutput> {
        const getRequest: AWS.S3.GetObjectRequest = {Bucket: this.params.bucketName, Key: objectId};
        if (offset && len)
            getRequest.Range = `bytes=${offset}-${offset + len}`;
        return this.s3.getObject(getRequest).on('build', function(req) {
            req.httpRequest.headers['Pragma'] = 'no-cache';
        }).promise();
    }

    getCredentials() {
        return {
            accessKeyId: this.params.accessKey,
            secretAccessKey: this.params.secretKey
        };
    }
};
