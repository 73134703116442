/* "Singleton" that loads WebAssembly code for the module only once. */
import loadModule from '../wasm/KeyTuple';

let WasmModulePromise = null;

// Need to compile WASM with --memoryprofiler for this to work
const DEBUG_MEM = false;

function setupMemoryDebugging(opts) {
    const counts = {
        malloc: 0,
        free: 0
    };
    const allocations = {};
    opts.onFree = (ptr) => {
        counts.free++;
        delete allocations[ptr];
        console.log('free ', ptr, allocations, counts);
    };

    opts.onMalloc = (ptr, size) => {
        counts.malloc++;
        allocations[ptr] = size;
        console.trace('malloc', ptr, allocations, counts);
    };
}

async function getWasmModule() {
    if (WasmModulePromise === null) {
        const opts = {};
        if (DEBUG_MEM)
            setupMemoryDebugging(opts);
        WasmModulePromise = loadModule(opts);
    }
    return await WasmModulePromise;
}

export default getWasmModule;
