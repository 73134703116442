import {LayoutRequest, LayoutResponse} from "../Layout";

const BASE_URL : string = "/ws";

function encodePath(path: string, params?: any) {
    let encoded = path;
    if (!params)
        return encoded;

    encoded += '?' + Object.entries(params).map(
        kv => kv.map(encodeURIComponent as any).join("=")).join("&");
    return encoded;
}

class LucidWebApiClient
{
    constructor(private url: string = BASE_URL) {
    }

    getLoginInfo(filespace: string, username: string) {
        return this.fetch(`fs/${filespace}/login/${username}`);
    }

    listDir(filespace: string, token: string, dirId: string)
    {
        return this.fetch(`fs/${filespace}/entries`, {parentId: dirId, token});
    }

    getEntry(filespace: string, token: string, entryId: string)
    {
        return this.fetch(`fs/${filespace}/entries`, {id: entryId, token});
    }

    readLayout(req: LayoutRequest) : Promise<LayoutResponse> {
        return this.fetch(`fs/${req.filespace}/layout/${req.fileId}`, {
            token: req.token,
            access: req.accessKeyId,
            secret: req.secretAccessKey
        });
    }

    private async fetch(path: string, params?: any)
    {
        const url = this.makeUrl(encodePath(path, params));
        console.log(`Fetching ${url} ...`);
        const resp = await fetch(url);
        if (!resp.ok)
            throw new Error(await resp.text());
        const respJson = await resp.json();
        console.log('Response: ', respJson);
        return respJson;
    }

    private makeUrl(path: string) {
        return this.url + '/' + path;
    }
}

export { LucidWebApiClient };
