// @ts-ignore
import { FileManager, FileNavigator } from '@opuscapita/react-filemanager';

import { connect } from "react-redux";
import React, { Component } from "react";

import lucidConnector from '../lucid/FileManagerConnector/index';
import LucidClientService from '../lucid/Client';
import { changeDir } from '../redux/actions';
import { setChildren } from '../redux/actions';
import { FsEntry } from '../lucid/Directory';


interface Props {
    client: LucidClientService,
    currentDir: FsEntry,
    parents: FsEntry[],
    children: FsEntry[],
    changeDir: (resource: FsEntry) => { type: string, payload: FsEntry }
    setParents: (resourceParents: FsEntry[]) => { type: string, payload: FsEntry[] }
    setChildren: (resourceChildren: FsEntry[]) => { type: string, payload: FsEntry[] }
};

interface State { };

class FileExplorer extends Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        return <div style={{ height: '480px' }}>
            <FileManager>
            <FileNavigator
                id="filemanager-1"
                api={lucidConnector.api}
                apiOptions={{client: this.props.client, currentDir: this.props.currentDir, children: this.props.children}}
                capabilities={lucidConnector.capabilities}
                listViewLayout={lucidConnector.listViewLayout}
                viewLayoutOptions={lucidConnector.viewLayoutOptions}
                onResourceChange={(resource: FsEntry) => this.props.changeDir(resource)}
                onResourceChildrenChange={(resourceChildren: FsEntry[]) => this.props.setChildren(resourceChildren)}
            />
            </FileManager>
        </div>
    }
};

function mapStateToProps(state: any) : any {
    return {
        client: state.clientService,
        currentDir: state.navigatorContext.currentDir,
        children: state.navigatorContext.children,
    };
}

const mapDispatchToProps = {
    changeDir,
    setChildren
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FileExplorer);
