import {LucidWebApiClient} from './webapiclient/WebApiClient';
import ObjectStore from './ObjectStore/ObjectStore';
import {AESCryptoKey} from './Crypto';
import FilespaceConfig from './FilespaceConfig';
import {BlockStream} from './BlockStream';

export class FileStore {
    private initialized: boolean = false;
    private keyTupleModule: any;

    constructor(private filespace: string,
        private token: string,
        private fsConfig: FilespaceConfig,
        private apiClient: LucidWebApiClient,
        private objectStore: ObjectStore,
        wasm: any) {
        this.keyTupleModule = wasm;
    }

    async read(cryptoKey: AESCryptoKey, fileId: string): Promise<BlockStream> {
        await this.initialize();

        const credentials = this.objectStore.getCredentials();
        const layout = await this.apiClient.readLayout({
            filespace: this.filespace,
            fileId: fileId,
            token: this.token,
            accessKeyId: credentials.accessKeyId,
            secretAccessKey: credentials.secretAccessKey
        });
        return new BlockStream(this.keyTupleModule, this.objectStore, layout, cryptoKey);
    }

    private initialize() {
        if (this.initialized)
            return;
        console.log('Setting block size to ', this.fsConfig.blockSize);
        this.keyTupleModule.BlockBufferAllocatorInstance().Reset();
        this.keyTupleModule.BlockBufferAllocatorInstance().Init(this.fsConfig.blockSize);
        this.initialized = true;
    }

};
