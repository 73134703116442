import LucidClientService from '../lucid/Client';
import { connect } from "react-redux";

import qs from 'qs';
import React, { Component } from "react";

interface Props {
    client: LucidClientService;
    location: any;
};

interface State { };

class VideoPlayer extends Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        const q = qs.parse(this.props.location.search, {ignoreQueryPrefix: true});
        return <div>
            Playing { q.id }
        </div>
    }
};

function mapStateToProps(state: any) {
    return { client: state.clientService };
}

export default connect(mapStateToProps)(VideoPlayer);
