import {AESCryptoKey, PrivateKey} from './Crypto';
import {arr2str} from './Util';

export const ROOT_ID: string = '65535:0';

export interface CryptoChainEntry {
    id: string;
    parentId: string;
    encryptedCryptoKey: string;
    keyIv: string;
}

export interface Share {
    id: string;
    name: string;
    cryptoKey: string;
    permissions: number;
};

export interface EncryptedShare {
    id: string;
    encryptedInfo: string;
};

export enum EntryType
{
    INVALID = 0,
    FILE,
    DIR,
    LINK
};

export interface FsEntry {
    cryptoKey: AESCryptoKey;
    creationTime: number;
    updateTime: number;
    size: number;
    id: string;
    parentId: string;
    type: EntryType;
    name: string;
    isShare: boolean;
};

export interface EncryptedDirEntry {
    type: EntryType;
    id: string,
    parentId: string,
    creationTime: number;
    updateTime: number;
    size: number;
    encryptedName: string;
    nameIv: string;
    encryptedCryptoKey: string;
    keyIv: string;
};

export interface DirInfo {
    entries: Array<FsEntry>;
    parentIds: Array<string>;
    cryptoKey: AESCryptoKey;
}

export interface EntryInfo {
    entry: FsEntry;
    parentIds: Array<string>;
    cryptoKey: AESCryptoKey;
}

export interface EncryptedDirInfo {
    cryptoChain: Array<CryptoChainEntry>;
    entries: Array<EncryptedDirEntry>;
    rootShares: Array<EncryptedShare>;
};

export interface EncryptedEntryInfo {
    cryptoChain: Array<CryptoChainEntry>;
    entries: Array<EncryptedDirEntry>;
    rootShares: Array<EncryptedShare>;
};

class Crypto {
    constructor(private privateKey: PrivateKey, private wasm: any) {
    }

    public async decryptEntries(cryptoKey : AESCryptoKey, encryptedDirEntries: EncryptedDirEntry[]): Promise<Array<FsEntry>> {
        let entries = [];
        for (const entry of encryptedDirEntries) {
            entries.push(await this.decryptEntry(entry, cryptoKey));
        }
        return entries;
    }

    public async decryptShares(shares: Array<EncryptedShare>): Promise<Array<Share>> {
        let result: Array<Share> = [];
        for (const share of shares) {
            result.push(await this.decryptShareInfo(share));
        }

        return result;
    }

    public async decryptShareInfo(shareInfo: EncryptedShare): Promise<Share> {
        const buf = new this.wasm.MutableBuffer(shareInfo.encryptedInfo);
        const kt = new this.wasm.KeyTuple(buf);

        const res = [];
        let totalLength = 0;
        for (let i = 0; i < kt.ElementCount(); ++i)
        {
            const keyEl = arr2str(kt.Get(i).AsMemRange());
            let decoded = atob(keyEl);
            const decrypted = await this.privateKey.decrypt(decoded);
            totalLength += decrypted.length;
            res.push(decrypted);
        }
        kt.delete();
        buf.delete();

        const decryptArr = new Int8Array(totalLength);
        let decryptIdx = 0;
        for (let i = 0; i < res.length; ++i) {
            for (let j = 0 ; j < res[i].length; ++j) {
                decryptArr[decryptIdx++] = res[i].charCodeAt(j);
            }
        }
        const decryptedBuf = new this.wasm.MutableBuffer(decryptArr);
        const decryptedTuple = new this.wasm.KeyTuple(decryptedBuf);

        const cryptoKeyEl = decryptedTuple.Get(2);
        const cryptoKey = arr2str(cryptoKeyEl.AsMemRange());
        cryptoKeyEl.delete();

        const nameEl = decryptedTuple.Get(1);
        const permEl = decryptedTuple.Get(3);
        const decryptedShare = {
            id: shareInfo.id,
            name: nameEl.AsString(),
            cryptoKey: cryptoKey,
            permissions: permEl.AsUint64()
        };
        nameEl.delete();
        permEl.delete();

        decryptedBuf.delete();
        decryptedTuple.delete();

        return decryptedShare;
    }

    public async getDirCryptoKey(dirId : string, rootShares : Array<Share>, cryptoChain: Array<CryptoChainEntry>) : Promise<AESCryptoKey> {
        let entryId = dirId;
        let rootShare: Share | undefined;
        let chain: Array<{encryptedCryptoKey: string, keyIv: string}> = [];
        while(true) {
            rootShare = this.findShare(entryId, rootShares);
            if (rootShare)
                break;

            const cryptoEntry: CryptoChainEntry | undefined = this.findCryptoChainEntry(entryId, cryptoChain);
            if (!cryptoEntry)
                throw new Error("Broken decryption chain!");

            chain.push({encryptedCryptoKey: cryptoEntry!.encryptedCryptoKey, keyIv: cryptoEntry!.keyIv});
            entryId = cryptoEntry!.parentId;
        }
        let decryptedKey = new AESCryptoKey(rootShare.cryptoKey);

        for (let i = chain.length - 1; i >= 0; --i)
        {
            const plainText = await decryptedKey.decrypt(chain[i].encryptedCryptoKey, chain[i].keyIv);
            decryptedKey = new AESCryptoKey(plainText);
        }

        return decryptedKey;
    }

    public isVirtualRoot(rootShares : Array<Share>) : boolean {
        return this.findShare(ROOT_ID, rootShares) === undefined;
    }

    private findShare(entryId : string, rootShares : Array<Share>) : Share | undefined {
        return rootShares.find(value => value.id === entryId);
    }

    private findCryptoChainEntry(entryId : string, cryptoChain: Array<CryptoChainEntry>) : CryptoChainEntry | undefined {
        return cryptoChain.find(value => value.id === entryId);
    }

    private async decryptEntry(entry : EncryptedDirEntry, parentCryptoKey: AESCryptoKey) : Promise<FsEntry> {
        const name = await parentCryptoKey.decrypt(entry.encryptedName, entry.nameIv);
        const rawCryptoKey = await parentCryptoKey.decrypt(entry.encryptedCryptoKey, entry.keyIv);
        const entryCryptoKey = new AESCryptoKey(rawCryptoKey);
        return {
            type: entry.type,
            id: entry.id,
            parentId: entry.parentId,
            creationTime: entry.creationTime,
            cryptoKey: entryCryptoKey,
            updateTime: entry.updateTime,
            size: entry.size,
            name: name,
            isShare: false
        };
    }

    public getEntryFromShare(share : Share) : FsEntry {
        return {
            type: EntryType.DIR,
            id: share.id,
            parentId: ROOT_ID,
            creationTime: 0,
            cryptoKey: new AESCryptoKey(share.cryptoKey),
            updateTime: 0,
            size: 0,
            name: share.name,
            isShare: true
        };
    }
};

export class Directory {
    private decryptedInfo: DirInfo | null;
    private crypto: Crypto;

    constructor(private dirId: string, privateKey: PrivateKey, private encryptedDirInfo: EncryptedDirInfo, wasm: any) {
        this.decryptedInfo = null;
        this.crypto = new Crypto(privateKey, wasm);
    }

    async init() {
        if (this.decryptedInfo)
            throw new Error('Already initialized!');

        await this.decrypt();
    }

    /* List all entries in the directory */
    list() : DirInfo {
        return this.decryptedInfo as DirInfo;
    }

    private async decrypt() {
        if (this.decryptedInfo)
            return;

        const cryptoChain = this.encryptedDirInfo.cryptoChain;
        const rootShares = await this.crypto.decryptShares(this.encryptedDirInfo.rootShares);
        let cryptoKey: AESCryptoKey;
        let entries: Array<FsEntry>;
        if (this.dirId === ROOT_ID && this.crypto.isVirtualRoot(rootShares)) {
            cryptoKey = new AESCryptoKey("");
            entries = rootShares.map(s => this.crypto.getEntryFromShare(s));
        }
        else if (this.encryptedDirInfo.entries.length === 0) {
            cryptoKey = new AESCryptoKey("");
            entries = [];
        }
        else {
            cryptoKey = await this.crypto.getDirCryptoKey(this.dirId, rootShares, cryptoChain);
            entries = await this.crypto.decryptEntries(cryptoKey, this.encryptedDirInfo.entries);
        }
        const parentIds = cryptoChain.map(function (e: CryptoChainEntry) { return e.id; });

        this.decryptedInfo = {
            cryptoKey,
            entries,
            parentIds
        };
    }
};

export class Entry {
    private decryptedInfo: EntryInfo | null;
    private crypto: Crypto;

    constructor(private entryId: string, privateKey: PrivateKey, private encryptedEntryInfo: EncryptedEntryInfo, wasm: any) {
        this.decryptedInfo = null;
        this.crypto = new Crypto(privateKey, wasm);
    }

    async init() {
        if (this.decryptedInfo)
            throw new Error('Already initialized!');

        await this.decrypt();
    }

    /* List all entries in the directory */
    get() : EntryInfo {
        return this.decryptedInfo as EntryInfo;
    }

    private async decrypt() {
        if (this.decryptedInfo)
            return;

        const cryptoChain = this.encryptedEntryInfo.cryptoChain;
        const rootShares = await this.crypto.decryptShares(this.encryptedEntryInfo.rootShares);
        let cryptoKey: AESCryptoKey;
        let entry: FsEntry;
        if (this.encryptedEntryInfo.entries.length === 0) {
            cryptoKey = new AESCryptoKey("");
            const share = rootShares.find(s => s.id === this.entryId);
            entry = this.crypto.getEntryFromShare(share!);
        }
        else {
            const encryptedEntry = this.encryptedEntryInfo.entries[0];
            cryptoKey = await this.crypto.getDirCryptoKey(encryptedEntry.parentId, rootShares, cryptoChain);
            const entries = await this.crypto.decryptEntries(cryptoKey, this.encryptedEntryInfo.entries);
            entry = entries[0];
        }
        const parentIds = cryptoChain.map(function (e: CryptoChainEntry) { return e.id; });
        this.decryptedInfo = {
            cryptoKey,
            entry,
            parentIds
        };
    }
};
