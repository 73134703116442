import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setClient } from '../redux/actions';
import { withRouter } from 'react-router-dom';

import logo from '../logo.svg';
import WithDefaultContext from '../context/DefaultContext';
import LoginService from '../lucid/Login';
import LucidClientService from '../lucid/Client';
import {LucidWebApiClient} from '../lucid/webapiclient/WebApiClient'
import ObjectStoreBuilder from '../lucid/ObjectStore/ObjectStoreBuilder';

interface Props {
    apiClient: LucidWebApiClient;
    loginService: LoginService;
    setClient: any;
    history: any;
    location: any;
    redirectedFrom: any;
};

interface State {
    filespace: string;
    user: string;
    password: string;
    error: string;
    successMsg: string;
};

class Login extends Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {filespace: '', user: '', password: '', error: '', successMsg: ''};
    }

    render() {
        return (
            <div className='login-container'>
            <img src={logo} className="logo" alt="logo" />
            <div className={this.state.error ? "alert alert-danger" : ""}>{this.state.error}</div>
            <div className={this.state.successMsg ? "alert alert-success" : ""}>{this.state.successMsg}</div>
            <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <input type="text" className="form-control" placeholder="Filespace name" value={this.state.filespace} onChange={this.onFilespaceChange}/>
                </div>

                <div className="form-group">
                    <input type="username" className="form-control" placeholder="User" value={this.state.user} onChange={this.onUserChange} />
                </div>

                <div className="form-group">
                    <input type="password" className="form-control" placeholder="Password" value={this.state.password} onChange={this.onPasswordChange}/>
                </div>

                <button type="submit" className="btn btn-primary btn-block">Login</button>
            </form>
            </div>
        );
    }

    private onFilespaceChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({filespace: e.currentTarget.value});
    }

    private onUserChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({user: e.currentTarget.value});
    }

    private onPasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({password: e.currentTarget.value});
    }

    private handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const user = this.state.user || 'root';
        try
        {
            const info = await this.props.loginService.login(this.state.filespace, user, this.state.password);
            console.log(`Succesfully logged in to ${this.state.filespace} as ${user}`);
            this.setState({successMsg: 'Succesfully logged in', error: ''});
            const objectStoreBuilder = new ObjectStoreBuilder(info.keys.configKey, info.config);
            const clientService = new LucidClientService(this.state.filespace, this.props.apiClient,
                await objectStoreBuilder.build(), info);
            await clientService.init();

            this.props.setClient(clientService);
            if (this.props && this.props.location && this.props.location.state && this.props.location.state.redirectedFrom) {
                this.props.history.push(this.props.location.state.redirectedFrom);
            } else {
                this.props.history.push('/files');
            }
        } catch (e)
        {
            this.setState({successMsg: '', error: e.message});
        }
    }
}

const mapDispatchToProps = {
    setClient
}
  
export default connect(
  null,
  mapDispatchToProps
)(withRouter(WithDefaultContext(Login) as any) as any);
